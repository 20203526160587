import crudMutations from "@/store/templates/b-crud/mutations";
import { cloneDeep } from "lodash";
import Vue from "vue";

export default {
  ...crudMutations,
  setName(state, name) {
    state.model.name = name;
  },
  setTargets(state, targets) {
    state.model.targets = targets;
  },
  setRefinements(state, refinements) {
    state.model.refinements = refinements;
  },
  addRefinement(state, refinement) {
    state.model.refinements.push(cloneDeep(refinement));
  },
  setRefinement(state, { refinementId, refinement }) {
    state.model.refinements[refinementId] = refinement;
  },
  setRefinementType(state, { refinementIndex, type }) {
    const previousType = state.model.refinements[refinementIndex].type;
    state.model.refinements[refinementIndex].type = type;
    if (type === "REPLACE" && previousType !== "REPLACE") {
      state.model.refinements[refinementIndex].alpha = [""];
    } else if (type !== "REPLACE" && previousType === "REPLACE") {
      state.model.refinements[refinementIndex].alpha = state.model.refinements[
        refinementIndex
      ].alpha = null;
    }
  },
  setRefinementBeta(state, { refinementIndex, beta }) {
    state.model.refinements[refinementIndex].beta = beta;
  },
  setRefinementAlpha(state, { refinementIndex, alpha }) {
    state.model.refinements[refinementIndex].alpha = alpha;
  },
  setRefinementIsCaseSensitive(state, { refinementIndex, isCaseSensitive }) {
    Vue.set(
      state.model.refinements[refinementIndex],
      "isCaseSensitive",
      isCaseSensitive
    );
  },
  deleteRefinement(state, index) {
    state.model.refinements.splice(index, 1);
  },
  setIsActive(state, isActive) {
    state.model.isActive = isActive;
  }
};
